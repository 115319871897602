var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input"},[(_vm.label)?_c('label',{staticClass:"v-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(((_vm.$attrs).type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[
      'v-input__item',
      { 'v-input__item_active': _vm.value },
      { 'v-input__item-datepicker': _vm.customTypeIsDatepicker } ],attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}},'input',_vm.$attrs,false),_vm.$listeners)):(((_vm.$attrs).type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[
      'v-input__item',
      { 'v-input__item_active': _vm.value },
      { 'v-input__item-datepicker': _vm.customTypeIsDatepicker } ],attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"change":function($event){_vm.model=null}}},'input',_vm.$attrs,false),_vm.$listeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[
      'v-input__item',
      { 'v-input__item_active': _vm.value },
      { 'v-input__item-datepicker': _vm.customTypeIsDatepicker } ],attrs:{"placeholder":_vm.placeholder,"type":(_vm.$attrs).type},domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}},'input',_vm.$attrs,false),_vm.$listeners))])}
var staticRenderFns = []

export { render, staticRenderFns }