<template>
  <div class="v-input">
    <label v-if="label" class="v-input__label">
      {{ label }}
    </label>

    <input
        v-model="model"
        v-bind="$attrs"
        v-on="$listeners"
        :class="[
        'v-input__item',
        { 'v-input__item_active': value },
        { 'v-input__item-datepicker': customTypeIsDatepicker },
      ]"
        :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'VInput',

  props: {
    customType: {
      type: String,
      default: null,
      validator (val) {
        return [
          'datepicker',
        ].includes(val) !== -1
      },
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        return this.$emit('change-value', value)
      },
    },

    customTypeIsDatepicker () {
      return this.customType === 'datepicker'
    },
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-input {
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 4px;
}

.v-input__label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #1E1E1E;

  @include dark-theme {
    color: $darkThemeTextLight;
  }
}

.v-input__item {
  padding: 0 11px;
  height: 40px;
  width: 100%;
  background-color: #FFF;
  border: 1px solid $borderColor;
  border-radius: 4px;
  outline: none;

  @include dark-theme {
    background-color: $darkThemeBgTwo;
    border-color: #2b2c30;
  }
}

.v-input__item_active,
.v-input__item:focus {
  border-color: #C0BEBE;

  @include dark-theme {
    border-color: #2b2c30;
  }

  &.v-input__item-datepicker {
    border-color: $borderColor;

    @include dark-theme {
      border-color: #2b2c30;
    }
  }
}

.v-input__item-datepicker {
  cursor: pointer;

  &:focus {
    border-color: $borderColor;
  }
}
</style>
