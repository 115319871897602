<template>
  <div
    v-click-outside="closeVisibleDatepicker"
    class="v-input-date"
  >
    <VInput
      :label="label"
      :value="selectedDate"
      customType="datepicker"
      readonly
      @click="toggleVisibleDatepicker"
    />

    <transition name="fade">
      <div v-if="datepickerIsVisible" class="v-input-date__datepicker">
        <VDatepicker v-model="date" />
      </div>
    </transition>
  </div>
</template>

<script>
import VInput from '@/components/ui/VInput';
import VDatepicker from '@/components/ui/VDatepicker';

export default {
  name: 'VInputDate',
  components: {
    VInput,
    VDatepicker,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    initialSelectedDate: {
      type: String,
      default: null,
    },
    plusDaysProp: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    timestamp: null,
    selectedDate: '',
    datepickerIsVisible: false,
  }),
  computed: {
    date: {
      get() {
        return new Date(this.timestamp);
      },
      set(value) {
        this.timestamp = value;
        this.setDate(value);
        this.toggleVisibleDatepicker();
      },
    },
    disabledDates() {
      const date = new Date();

      date.setDate(date.getDate() - 1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      return {
        to: date,
      };
    },
  },

  watch: {
    selectedDate() {
      this.$emit('select-date', this.selectedDate);
      this.closeVisibleDatepicker();
    },
  },
  methods: {
    toggleVisibleDatepicker() {
      this.datepickerIsVisible = !this.datepickerIsVisible;
    },
    closeVisibleDatepicker() {
      if (this.datepickerIsVisible) {
        this.datepickerIsVisible = false;
      }
    },

    async setInitialDate() {
      await this.$nextTick();

      if (this.initialSelectedDate) {
        const date = this.initialSelectedDate
          .split('.')
          .reverse()
          .join(' ');
        this.timestamp = Date.parse(date);
        this.selectedDate = this.initialSelectedDate;
      } else {
        const now = new Date();
        this.timestamp = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        this.setDate(new Date(), this.plusDaysProp);
      }
    },
    setDate(timestamp, plusDays = 0) {
      const date = new Date(timestamp);

      const day = ('0' + (date.getDate() + plusDays)).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      this.selectedDate = `${day}.${month}.${date.getFullYear()}`;
    },
  },

  async created() {
    await this.setInitialDate();
  },
}
</script>

<style lang="scss">
.v-input-date__datepicker {
  z-index: 1;
  position: absolute;
  top: 68px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .07);
  border-radius: 4px;

  .vdp-datepicker__calendar {
    width: 100%;
    border: none;

    .cell {
      font-size: rem(12px);
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-input-date {
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
